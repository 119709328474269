<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'hybrid-tour',
  primary_color: '#8051D0',
  secondary_color: '#3B2E58',
  header: {
    background: {
      image: `/projekt/hybrid-tour/elbworx-projekt-microsoft-hybrid_work_tour-header-3540_LQ80.webp`,
    },
    project_class: 'text-secondary',
    project: 'Microsoft Hybrid Work Tour',
    headline: 'Unterwegs arbeiten mit der Cloud',
    info_card: {
      description: 'Arbeiten im Hybriden Zeitalter',
      client: 'Microsoft Deutschland',
      roles: [
        'Autofolierung',
        'Car-Branding',
        'Illustration',
        'Video',
        'KeyVisual',
        'Social Media',
      ],
      project_duration: 'Juli–September 2021',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="!text-primary">Die Herausforderung</h3>

      <p>
        Mit unserem langjährigen Kunden Microsoft arbeiten wir immer wieder an interessanten
        Projekten. Bei der Hybrid Work Tour vom 20. – 24. September 2021 konnte man hybrides
        Arbeiten live bei einer Tour quer durch Deutschland aus Wohnmobilen erleben.
      </p>
      <p>
        Um das Konzept des hybriden Arbeitens visuell darzustellen und die Botschaften klar zu
        vermitteln, haben wir Illustrationen erstellt, die die Verbindung zwischen traditionellen
        Arbeitsumgebungen und modernen Technologien verdeutlichen. Die erstellten Illustrationen und
        die konzipierte Bildwelt wurden nicht nur in digitalen Assets, sondern auch in
        Print-Produkten angewendet. Buttons, Beachflags, Liegestühle und andere physische
        Materialien wurden gestaltet, um die Mitarbeiter von Microsoft zu inspirieren. Gleichzeitig
        wurden die digitalen Assets auf Online-Plattformen, in Präsentationen und auf internen
        Kommunikationskanälen verwendet, um die Botschaften des Projekts weitreichend zu verbreiten.
      </p>
    </template>

    <div class="overflow-clip">
      <div class="my-20 -ml-[5%] w-[110%]">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-microsoft-hybrid_work_tour-tourbusse.svg`"
        />
      </div>
    </div>

    <Tilt contain class="bg-secondary">
      <Padding class="flex justify-center py-16">
        <Device
          class="w-full max-w-5xl"
          :element="{
            device_type: 'desktop',
            frame_color: 'var(--color-primary)',
          }"
        >
          <video
            :src="`${projectPath}elbworx-hybrid-1080.mp4`"
            muted
            class="h-full w-full object-cover"
            autoplay
            loop
            playsinline
          />
        </Device>
      </Padding>
    </Tilt>

    <ImageSwiper
      class="mt-24"
      :space-between="16"
      :images="[
        {
          url: `${projectPath}elbworx-projekt-microsoft-hybrid_work_tour-slider_left-1920.webp`,
          alt: 'auto',
        },
        {
          url: `${projectPath}elbworx-projekt-microsoft-hybrid_work_tour-slider_center-1920.webp`,
          alt: 'auto',
        },
        {
          url: `${projectPath}elbworx-projekt-microsoft-hybrid_work_tour-slider_right-1920.webp`,
          alt: 'auto',
        },
      ]"
    />

    <Padding class="mt-20 flex justify-center">
      <Text class="!max-w-3xl">
        <h3 class="!text-primary">Key Visuals</h3>
        <p>
          Für das Event sind diverse Versionen des KeyVisuals entstanden, die je nach Situation und
          Medium unterschiedlich eingesetzt werden konnten. So wurden die einzelnen Stationen, die
          Fahrzeuge und das Thema "Cloud Computing" immer wieder neu repräsentiert.
        </p>
      </Text>
    </Padding>
    <Grid class="mt-32 items-end">
      <div class="col-span-10 col-end-13">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-microsoft-hybrid_work_tour-chandeliers-2560_LQ80.webp`"
        />
      </div>
    </Grid>
    <Grid class="items-start">
      <div class="col-span-5 sm:row-start-1 sm:row-end-3">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-microsoft-hybrid_work_tour-flag-1789.webp`"
        />
      </div>
      <div class="sm:row-start-1 sm:row-end-2 md:col-span-7">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-referenz-microsoft-hybrid_work_tour-van-2560.webp`"
        />
      </div>
      <div class="col-span-5 col-start-6 sm:row-start-2">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-referenz-microsoft-hybrid_work_tour-shirt-1920.webp`"
        />
      </div>
    </Grid>
    <div class="mt-4">
      <Image
        class="w-full object-cover"
        :src="`${projectPath}elbworx-referenz-microsoft-hybrid_work_tour-chair_sticker-3500.webp`"
      />
    </div>

    <Padding class="mt-28 flex justify-center">
      <Text class="!max-w-3xl">
        <h3 class="!text-primary">Hybrid Work Tour</h3>
        <p>
          Das Projekt „Hybrid Work Tour“ war eine aufregende Gelegenheit, gemeinsam mit einem
          Technologieführer an der Gestaltung der Arbeitswelt der Zukunft zu arbeiten. Durch die
          Kombination von interaktiven Webinaren, ansprechenden Illustrationen und einer
          ganzheitlichen Bildwelt konnten wir nicht nur Wissen vermitteln, sondern auch die Vision
          von effizientem, flexiblen und innovativem Arbeiten in einer hybriden Umgebung lebendig
          werden lassen.
        </p>
      </Text>
    </Padding>
  </Project>
</template>
